import React, { Component } from 'react';
// import CurrentOverview from "./ShortTermOverview";
import { Box, Text, Anchor } from "grommet";
import styled from 'styled-components';
import StatusContent from '../components/StatusContent'
import StatusIconline from '../components/StatusIconline';
// import microfocus from '@micro-focus/quantum-ux-grommet-theme-v2';
// import { deepMerge } from "grommet/utils";
import { ReactComponent as IconHeartBeat } from "../icons/icon-heart-beat.svg";
import { ReactComponent as IconIncidentReport } from "../icons/icon-incident-report.svg";
import { ReactComponent as IconMaintenance } from "../icons/icon-maintenance.svg";
import MaintenanceWin from '../components/MaintenanceWin';
import IncidentWin from '../components/IncidentWin';
// import { brandColor } from '../config';
import { getCurrentSLA, getSysAlert } from "../utils/DBUtils";
import { getFormattedDate, getTimezoneOffsetMark } from "../utils/CommonUtil";
import HeadBar from '../views/HeadBar';
import FootBar from '../views/FootBar';
import SLAHistory from '../components/MUI/SLAHistory';
import { OPS_DATA_URL, FARM_ALIAS, MODE } from "../config";
import SystemAlert from './SystemAlert';
import './app.css';

const qs = require('query-string');
const SLAURLs = `${OPS_DATA_URL}/slaitems`;
const SYS_ALERT_URL = `${OPS_DATA_URL}/alerts`;
const TimezoneOffset = getTimezoneOffsetMark();

const Oval = styled(Box)`
  width: 23px;
  height: 2px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.31);
`

const IconBox = styled(Box)`
  margin: 22px 20px 10.5px
  width: 32px;
  height: 32px;
`

const PrefixText = styled(Text)`
  height: 100%;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  // line-height: 28px;
  letter-spacing: 0.14px;
  color: #767676;
  margin-right: 8px;
`

const TitleText = styled(Text)`
  margin-top: 20px;
  width: auto;
  height: 100%;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #333333;
`
const HighlightText = styled(Text)`
  width: auto;
  height: 100%;
  font-size: 22px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  color: #000000;
`

const ReportAnchor = styled(Anchor)`
  width: auto;
  height: 100%;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: #000000;
`

const SLACard = (props) => (
  <Box pad="5px" >
    <div id="subdashborad">
      <Box align="center">
        <IconBox>{props.icon}</IconBox>
        <Oval />
      </Box>
      <Box align="start" alignContent="start" >
        <TitleText>{props.title}</TitleText>
        <HighlightText>{props.body}</HighlightText>
        <ReportAnchor onClick={props.link} label={props.linkText} />
      </Box>
    </div>
  </Box>
);

const ReportCard = (props) => (
  <Box overflow="auto" >
    <div id="subdashborad">
      <Box align="center">
        <IconBox>{props.icon}</IconBox>
        <Oval />
      </Box>
      <Box overflow="auto">
        <TitleText>{props.title}</TitleText>

        <PrefixText>UTC{TimezoneOffset}</PrefixText>
        <HighlightText size="20px" weight="bold">{props.body}</HighlightText>


        <ReportAnchor href={props.link} label={props.linkText} />
      </Box>
    </div>
  </Box>
);

const StatusTitleText = styled(Text)`
  width: 149px;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  color: #333333;
`

const StatusTimeText = styled(Text)`
  
  height: 15px;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0.14px;
  margin-left: 8px;
  color: #767676;
`

const LineMarge = styled(Box)`
  width: 1102px;
  height: 0.5px;
  border: solid 0.5px #cccccc;
`


// const FARM_VERSION = ['2020.02', '2020.02', '2020.02', '2020.02'];
const DataLoading = (<div className="qtm-loader"></div>);

const getIndexFromURL = (props) => {
  let searchString = props.location.search;
  let defaultFarmIndex = 1;

  if (searchString) {
    const uRLFarm = qs.parse(searchString, { ignoreQueryPrefix: true }).farm;
    // console.log("The URL farm parameter is:" + uRLFarm);
    let index = FARM_ALIAS.indexOf(uRLFarm);
    defaultFarmIndex = index < 0 ? 1 : index;
  }
  return defaultFarmIndex;

}

const getModeFromURL = (props) => {
  let searchString = props.location.search;
  let defaultModeIndex = 0;

  if (searchString) {
    const uRLFarm = qs.parse(searchString, { ignoreQueryPrefix: true }).mode;
    // console.log("The URL farm parameter is:" + uRLFarm);
    let index = MODE.indexOf(uRLFarm);
    defaultModeIndex = index < 0 ? 0 : index;
  }
  return defaultModeIndex;

}

class Dashboard extends Component {

  constructor(props) {
    super(props);
    // let searchString = this.props.location.search;
    let defaultFarmIndex = getIndexFromURL(this.props);
    let defaultModeIndex = getModeFromURL(this.props);

    this.state = {
      // currentStatus:null,
      // currentFarm: FARMS[defaultFarmIndex],
      selectedFarmIndex: defaultFarmIndex,
      selectedModeIndex: defaultModeIndex,
      lastIncident: DataLoading,
      lastMaintenance: DataLoading,
      SLACount: DataLoading,
      SLAPeriod: DataLoading,
      currentTime: new Date(),
      systemAlert: null
    }
    // this.contentRef = React.createRef();
    this.SLARef = React.createRef();
    this.updateLastIncident = this.updateLastIncident.bind(this);
    this.updateLastMaintenance = this.updateLastMaintenance.bind(this);
    this.popUpSLAHistory = this.popUpSLAHistory.bind(this);
    // Change the window.location
    if (defaultModeIndex === 0) {
      this.props.history.push(`/dashboard/?farm=${FARM_ALIAS[defaultFarmIndex]}`);
    } else {
      this.props.history.push(`/dashboard/?farm=${FARM_ALIAS[defaultFarmIndex]}&mode=${MODE[defaultModeIndex]}`);
    }
  }

  componentDidMount() {
    // console.log("current Farm is " + this.state.currentFarm);
    const { selectedFarmIndex } = this.state;
    this._updateSLA(selectedFarmIndex);
    this._checkAlert(selectedFarmIndex);
    setInterval(() => this.setState({ currentTime: new Date() }), 1000);
    setInterval(() => this._checkAlert(selectedFarmIndex), 1000 * 30);
  }

  async _checkAlert(index) {
    const url = `${SYS_ALERT_URL}/${FARM_ALIAS[index]}`;
    const alertItem = await getSysAlert(url);
    this.setState({ systemAlert: alertItem })
  }

  async _updateSLA(index) {
    const url = `${SLAURLs}/${FARM_ALIAS[index]}`;
    const { SLACount, SLAPeriod } = await getCurrentSLA(url);
    this.setState({ SLACount, SLAPeriod });
  }

  popUpSLAHistory() {
    this.SLARef.current.showModal();
  }


  updateLastIncident(incident) {
    if (incident.start_time) {
      let time = new Date(incident.start_time);
      this.setState({
        lastIncident: getFormattedDate(time)
      })
    } else {
      this.setState({
        lastIncident: 'No incident occurred'
      })
    }
  }

  updateLastMaintenance(maintenance) {
    if (maintenance.start_time && new Date(maintenance.start_time) > new Date()) {
      let time = new Date(maintenance.start_time);
      this.setState({
        lastMaintenance: getFormattedDate(time)
      })
    } else {
      this.setState({
        lastMaintenance: 'Currently not scheduled'
      })
    }
  }

  render() {
    // const { currentFarm, selectedFarmIndex, currentTime } = this.state;
    const { selectedFarmIndex, selectedModeIndex, currentTime, systemAlert, SLAPeriod, SLACount, lastIncident, lastMaintenance } = this.state;

    return (

      <Box>
        {systemAlert ? <SystemAlert subject={systemAlert.subject} /> : null}
        <HeadBar farmCode={FARM_ALIAS[selectedFarmIndex]} />
        <Box background="#f5f7f8" align="center">
          <Box align="center" id="dashboardArea" >

            <Box justify="start" width="1102px" align="start" id="dashText">
              <StatusTitleText>Dashboard</StatusTitleText>
            </Box>

            <Box justify="evenly" id="subDashboard" width="1102px"  >
              <div id="dashboard" >
                <SLACard icon={<IconHeartBeat />}
                  title={SLAPeriod}
                  body={SLACount}
                  linkText="View the SLA history"
                  link={this.popUpSLAHistory} />
                <SLAHistory ref={this.SLARef} farmIndex={selectedFarmIndex} />
                <ReportCard icon={<IconIncidentReport />}
                  title="Incident in the past 90 days"
                  body={lastIncident}
                  linkText="Open the incident report"
                  link="#incident" />
                <ReportCard icon={<IconMaintenance />}
                  title="Planned maintenance schedule"
                  body={lastMaintenance}
                  linkText="Open the schedule details"
                  link="#maintenance" />
              </div>
            </Box>
          </Box>
        </Box>
        <Box background="#ffffff" align='center'>


          <Box justify="start" width="1102px" direction='row' style={{ marginTop: '35px', marginLeft: "10px" }} >
            <StatusTitleText>Service Status</StatusTitleText>
            <div>
              <StatusTimeText>Current: UTC{TimezoneOffset}  {getFormattedDate(currentTime)}</StatusTimeText>
            </div>
          </Box>
          <Box justify="center" >
            <StatusContent farm={selectedFarmIndex} mode={selectedModeIndex} /*ref={this.contentRef}*/ />
          </Box>
          <Box justify="end" >
            <StatusIconline />
          </Box>


          <Box style={{ marginTop: '20px' }} id="incident">
            <LineMarge />
          </Box>

          <IncidentWin updateLast={this.updateLastIncident} farmCode={FARM_ALIAS[selectedFarmIndex]} />

          <Box style={{ marginTop: '20px' }} id="maintenance">
            <LineMarge />
          </Box>
          <MaintenanceWin updateLast={this.updateLastMaintenance} farmCode={FARM_ALIAS[selectedFarmIndex]} />
          <Box style={{ marginTop: '20px' }}>
          </Box>
        </Box>
        <FootBar back2top={true} />
      </Box>
    )
  }
}

export default Dashboard;
