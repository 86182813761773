import { userPoolWebClientId } from "../config"

const getCognitoAttrPrefix = () => {
  const ClientPrefix = `CognitoIdentityServiceProvider.${userPoolWebClientId}`;
  let lastUserkey = `${ClientPrefix}.LastAuthUser`;
  let lastAuthUserId = localStorage.getItem(lastUserkey);
  return `${ClientPrefix}.${lastAuthUserId}`;
}

export const authenticateToken = () => {
  let idTokenKey = `${getCognitoAttrPrefix()}.idToken`;
  let idToken = localStorage.getItem(idTokenKey);
  return idToken;
}

export const getAuthEmailFromToken = () => {
  let userDataKey = `${getCognitoAttrPrefix()}.userData`;
  let userData = localStorage.getItem(userDataKey);
  let attr = JSON.parse(userData).UserAttributes;
  for(let item of attr){
    if(item.Name === "email"){
      return item.Value;
    }
  }
}


// import { Auth } from 'aws-amplify';
// const jwtDecode = require('jwt-decode');
// const AmazonCognitoIdentity = require('amazon-cognito-identity-js');


// const poolData = {
//   UserPoolId: userPoolId,
//   ClientId: userPoolWebClientId
// };

// const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

// export const signOutUser = () => {
//   let cognitoUser = userPool.getCurrentUser();
//   if (cognitoUser != null) {
//     cognitoUser.signOut()
//   }
// }

// export const getAuthEmailFromToken = (token) => {
//   let info = jwtDecode(token);
//   if (info) {
//     return info.email
//   } else {
//     return null
//   }
// }

// export const authenticateToken = new Promise(function fetchCurrentAuthToken(resolve, reject) {
//   let cognitoUser = userPool.getCurrentUser();

//   if (cognitoUser) {
//     cognitoUser.getSession(function sessionCallback(err, session) {
//       if (err) {
//         reject(err);
//       } else if (!session.isValid()) {
//         resolve(null);
//       } else {
//         resolve(session.getIdToken().getJwtToken());
//       }
//     });
//   } else {
//     resolve(null);
//   }
// });

// const authUser = (cognitoUser, authenticationDetails, password, callback) => {
//   let isFirstLogin = false;
//   cognitoUser.authenticateUser(authenticationDetails,
//     {
//       onSuccess: function (result) {
//         // console.log('result' + JSON.stringify(result))
//         if (isFirstLogin) {
//           cognitoUser.changePassword('Password3,', password, (err, result) => { });
//           console.log(' cognitoUser.changePassword')
//           isFirstLogin = false;
//         }
//         let accessToken = result.getAccessToken()
//         let token = accessToken.getJwtToken();
//         console.log('token: ' + token)
//         callback(null, token)
//         // return token
//       },
//       onFailure: function (err) {
//         console.log("auth fail:" + err.message || JSON.stringify(err))
//         callback('err333', err.message)
//         // return err
//       },
//       newPasswordRequired: function (userAttributes, requiredAttributes) {
//         cognitoUser.completeNewPasswordChallenge('Password3,', null, this);
//         console.log(' cognitoUser.completeNewPasswordChallenge')
//         isFirstLogin = true;
//       }
//     })
// }

// const verifyUser = (username, password, callback) => {
//   // console.log("username:", username)
//   // console.log("password:", password)
//   let authenticationData = {
//     Username: username,
//     Password: password,
//   };
//   let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

//   let userData = {
//     Username: username,
//     Pool: userPool
//   };

//   let cognitoUser = userPool.getCurrentUser()

//   // console.log("invoke auth" + JSON.stringify(cognitoUser))
//   // console.log("authenticationDetails " + JSON.stringify(authenticationDetails))
//   if (cognitoUser != null) {
//     cognitoUser.getSession((err, result) => {
//       if (err) {
//         console.log('err:' + err);
//         authUser(cognitoUser, authenticationDetails, password, callback)
//       } else {
//         callback(null, result.getAccessToken().getJwtToken())
//       }

//     })
//   } else {
//     cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
//     authUser(cognitoUser, authenticationDetails, password, callback);
//   }
// }

// export default verifyUser