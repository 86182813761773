import React, { Component } from 'react';
// import {faCheckCircle, faExclamationTriangle, faTimesCircle} from "@fortawesome/free-solid-svg-icons/index";
// import { Tooltip } from 'antd';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLocalHour, isMainHour } from "../utils/CommonUtil";
import { StatusGoodSmall } from 'grommet-icons';
class StatusIcon extends Component {

  render() {
    const { status, time } = this.props;
    // let rectangleStyle={
    //     height:'30px',
    //     width:'30px',
    //     margin:'auto',
    //     textAlign:'center',
    //     padding:'0px'
    // };

    // let dotStyle={
    //     height:'6px',
    //     width:'6px',
    //     backgroundColor: '#1aac60',
    //     borderRadius: '50%',
    //     margin:'12px 12px 12px 12px'
    // };
    // const rectangle=(<div style={rectangleStyle}>
    //     <Tooltip title="Normal">
    //         <FontAwesomeIcon size='2x' icon={faCheckCircle} color='#1aac60' style={{margin:'auto',padding:'0px'}} />
    //     </Tooltip></div>);
    // const dot=(<div style={rectangleStyle}><Tooltip title="Normal"><div style={dotStyle} /></Tooltip></div>);
    // const warnRectangle=(<div style={rectangleStyle}>
    //     <Tooltip title="Warning">
    //         <FontAwesomeIcon size='2x' icon={faExclamationTriangle} color='#ffc002' style={{margin:'0px',padding:'0px'}}/>
    //     </Tooltip></div>);
    // const errorRectangle=(<div style={rectangleStyle}>
    //     <Tooltip title="Error">
    //         <FontAwesomeIcon size='2x' icon={faTimesCircle} color='#e5004c' style={{margin:'0px',padding:'0px'}}/>
    //     </Tooltip> </div>);

    const localtime = getLocalHour(time);

    const pointStyle = {
      height: "41px"
    }

    const okColor = "#1aac60";
    const warnColor = "#ffc002";
    const errorColor = "#e5004c";
    const nodataColor = "#bdbec0";

    const bigok = (<StatusGoodSmall color={okColor} size="15px" style={pointStyle} />);
    const bigwarn = (<StatusGoodSmall color={warnColor} size="15px" style={pointStyle} />);
    const bigerror = (<StatusGoodSmall color={errorColor} size="15px" style={pointStyle} />);
    const bignodata = (<StatusGoodSmall color={nodataColor} size="15px" style={pointStyle} />);

    const smallok = (<StatusGoodSmall color={okColor} size="9px" style={pointStyle} />);
    // const smallwarn = (<StatusGoodSmall color={warnColor} size="9px" style={pointStyle} />);
    // const smallerror = (<StatusGoodSmall color={errorColor} size="9px" style={pointStyle} />);
    const smallnodata = (<StatusGoodSmall color={nodataColor} size="9px" style={pointStyle} />);

    switch (status) {
      case 'green':
        if (isMainHour(localtime)) {
          return (bigok);
        } else {
          return (smallok);
        }
      case 'red':
        return (bigerror);
      case 'error':
        return (bigerror);
      case 'nodata':
        if (isMainHour(localtime)) {
          return (bignodata);
        } else {
          return (smallnodata);
        }
      case 'yellow':
        if (isMainHour(localtime)) {
          return (bigwarn);
        } else {
          return (bigwarn);
        }
      default:
        return (bignodata);
    }
  }
}

export default StatusIcon;
