import React, { Component } from 'react';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Add, Edit, Trash } from "grommet-icons";
import MaintenceDataModalPage from '../ModalPages/MaintenceDataModalPage'
import DeleteDataModalPage from '../ModalPages/DeleteDataModalPage'
import EditDataModalPage from '../ModalPages/EditDataModalPage'

const defaultToolbarStyles = {
  IconButton: {
  }
}

class CustomToolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      title: this.props.popUptitle,
      isEdit: false,
      isRowSelect: this.props.isRowSelect,
      alertVisible: false,
      editVisible: false,
      alertTitle: '',
      fields: {},
      data: this.props.data,
      type: this.props.type
    }
  }

  showAddModal = () => {
    this.setState({
      visible: true,
      isEdit: false,
      title: 'New ' + this.props.popUptitle
    });
  }

  showEditModal = () => {
    const { idArrChecked, rowData } = this.props
    if (idArrChecked && idArrChecked.length === 1) {
      this.setState({
        visible: true,
        isEdit: true,
        title: 'Edit ' + this.props.popUptitle,
        fields: {
          id: rowData[1],
          farm: rowData[2],
          subject: rowData[3],
          create_time: rowData[4],
          desc: rowData[5],
          start_time: rowData[6],
          end_time: rowData[7]
        }
      });
    } else {
      this.setState({
        editVisible: true,
        alertTitle: 'Edit-Warning',
        showEditWindow: true
      })
    }

  };

  showDeleteModal = () => {
    const { idArrChecked } = this.props;
    if (idArrChecked && (idArrChecked.length > 0 || idArrChecked > 0)) {
      this.setState({
        alertVisible: true,
        alertTitle: 'Delete',
        fields: {
          ids: idArrChecked
        }
      });
    } else {
      this.setState({
        alertVisible: true,
        alertTitle: 'Warning'
      });
    }
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      alertVisible: false,
      editVisible: false,
      isEdit: false,
      fields: {}
    });
    let notifaction = {
      text: '',
      item: '',
      visible: false
    }
    this.props.refresh(notifaction)
  };

  handleOk = (status) => {
    let notifaction
    if (status) {
      notifaction = {
        text: 'Operate successfully!',
        item: 'success',
        visible: true
      }
    } else {
      notifaction = {
        text: 'Operate fail!',
        item: 'error',
        visible: true
      }
    }
    this.setState({
      visible: false,
      editVisible: false,
      alertVisible: false
    });

    this.props.refresh(notifaction)
  }

  render() {
    const { classes, idArrChecked } = this.props;
    const { title, visible, alertVisible, alertTitle, editVisible, isEdit, fields, type } = this.state
    return (
      <React.Fragment>
        <Tooltip title={"New"}>
          <IconButton className={classes.iconButton} onClick={() => this.showAddModal()}>
            <Add />
          </IconButton>
        </Tooltip>

        <Tooltip title={"Edit"}>
          <IconButton className={classes.iconButton} onClick={() => this.showEditModal()}>
            <Edit />
          </IconButton>
        </Tooltip>

        <Tooltip title={"Delete"}>
          <IconButton className={classes.iconButton} onClick={() => this.showDeleteModal()}>
            <Trash />
          </IconButton>
        </Tooltip>

        <MaintenceDataModalPage
          visible={visible}
          title={title}
          isEdit={isEdit}
          onCancel={this.handleCancel}
          onSubmit={this.handleOk}
          fields={fields}
          type={type}

        >
        </MaintenceDataModalPage>

        <DeleteDataModalPage
          alertVisible={alertVisible}
          alertTitle={alertTitle}
          onCancel={this.handleCancel}
          onSubmit={this.handleOk}
          fields={fields}
          type={type}
          idArrChecked={idArrChecked}

        >
        </DeleteDataModalPage>

        <EditDataModalPage
          editVisible={editVisible}
          alertTitle={alertTitle}
          isEdit={isEdit}
          onCancel={this.handleCancel}
          onSubmit={this.handleOk}
          fields={fields}
          type={type}
          idArrChecked={idArrChecked}
        >
        </EditDataModalPage>

      </React.Fragment>

    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
