import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import './App.css'
import { farmDetails as Farms } from '../../config';

const SortBar = (props) => {

    return (
        <div style={{ background: "white", borderBottomStyle: "solid", borderBottomWidth: "thin" }}>
            <Container>
                <Row >
                    <Col xs={10}>
                        <Button onClick={() => props.setValue("All")} variant="none"> All({Farms.length})</Button>
                        <Button onClick={() => props.setValue("Oregon")} variant="none"> Oregon({Farms.filter(item => item.location === "Oregon").length})</Button>
                        <Button onClick={() => props.setValue("Frankfurt")} variant="none">Frankfurt({Farms.filter(item => item.location === "Frankfurt").length})</Button>
                        <Button onClick={() => props.setValue("Sydney")} variant="none"> Sydney({Farms.filter(item => item.location === "Sydney").length})</Button>
                        <Button onClick={() => props.setValue("Tokyo")} variant="none">Tokyo({Farms.filter(item => item.location === "Tokyo").length})</Button>
                        <Button onClick={() => props.setValue("São Paulo")} variant="none">São Paulo({Farms.filter(item => item.location === "São Paulo").length})</Button>
                    </Col>

                    <Col></Col>
                </Row>
            </Container>
        </div>
    )
}

export default SortBar;