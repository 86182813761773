import React, { Component } from 'react';
import { Form, Input, Button, Switch, message } from 'antd';
import { Box } from 'grommet';
import { OPS_DATA_URL } from '../config';
import { getAuthEmailFromToken, authenticateToken } from '../utils/auth';

const { TextArea } = Input;

class ConfigForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      configItem: props.configitem,
      teams: props.configitem.teams,
      // savDisabled: true,
      saving: false,
      removing: false
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return console.log(err);
      }
      this.setState({ saving: true });
      // console.log('Received values of form: ', values);
      let configItem = values;
      let authToken = authenticateToken();
      let operator = getAuthEmailFromToken();
      configItem.operator = operator;
      configItem.farm = this.state.configItem.farm;
      var fetchOption = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken
        },
        body: JSON.stringify(configItem)
      }
      let url = `${OPS_DATA_URL}/configitems`
      fetch(url, fetchOption).then(
        res => res.json().then(result => {
          this.setState({ saving: false, configItem });
          message.success('Configurations has been saved');
        }
        ).catch((err) => {
          this.setState({ saving: false });
          message.error(err);
        }))
    }
    );
  };

  handleDelete = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return console.log(err);
      }
      this.setState({ removing: true });
      // console.log('Received values of form: ', values);
      let configItem = values;
      let authToken = authenticateToken();
      let operator = getAuthEmailFromToken();
      configItem.operator = operator;
      configItem.farm = this.state.configItem.farm;
      var fetchOption = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken
        },
        body: JSON.stringify(configItem)
      }
      let url = `${OPS_DATA_URL}/configitems`
      fetch(url, fetchOption).then(
        res => res.json().then(result => {
          this.setState({ removing: false });
          message.success('Configurations has been removed');
          window.location.reload();
        }
        ).catch((err) => {
          this.setState({ removing: false });
          message.error(err);
          window.location.reload();
        }))
    }
    );
  };

  // verifyRecover = () => {
  //   const { smaxver, teams, webhook } = this.props.form.getFieldsValue();
  //   const teamsChecked = teams ? true : false;
  //   const { configItem } = this.state;
  //   console.log("smaxver:", smaxver, "webhook:", webhook, "teams:", teamsChecked, "configItem:", configItem);
  //   if (smaxver === configItem.smaxver
  //     && teamsChecked === configItem.teams
  //     && (webhook === configItem.webhook)) {
  //     this.setState({ savDisabled: true });
  //   }
  // }

  // onVersionChange = event => {
  //   const { value } = event.target;
  //   if (value !== this.state.configItem.smaxver
  //     && this.state.savDisabled) {
  //     this.setState({ savDisabled: false });
  //   }
  // }

  onTeamsAlarmChange = teams => {
    // this.props.form.validateFields((err, values) => {
    //   if (err) {
    //     return console.log(err);
    //   }
      this.setState({ teams });
    // })

    // if (teams !== this.state.configItem.teams) {
    //   this.setState({ savDisabled: false });
    // }
  }

  // onWebhookChange = event => {
  //   const { value } = event.target;
  //   if (value !== this.state.configItem.webhook
  //     && this.state.savDisabled) {
  //     this.setState({ savDisabled: false });
  //   }
  // }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { configItem, teams, saving, removing } = this.state;
    return (
      <Box>
        <Form onSubmit={this.handleSubmit}>
          {configItem.farm !== 'global' ?
            (<Form.Item label="Version">
              {getFieldDecorator('smaxver', {
                initialValue: configItem.smaxver || "",
                rules: [{ required: false, message: 'Please input the version of this farm!' }],
              })(<Input style={{ width: "150px" }}/>)}
            </Form.Item>) : <span />
          }
          <Form.Item label="Microsoft Teams Alarm">
            {getFieldDecorator('teams', {
              initialValue: configItem.teams || false,
            })(<Switch checked={teams} onChange={this.onTeamsAlarmChange} />)}
          </Form.Item>
          <Form.Item label="Microsoft Teams Webhook">
            {getFieldDecorator('webhook', {
              initialValue: configItem.webhook || "",
              rules: [{ required: teams, message: 'Please input the teams webhook of this farm!' }],
            })(<TextArea disabled={!teams} style={{ width: "700px" }} />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={saving}>Save</Button>
            <Button type="danger" loading={removing} onClick={this.handleDelete} style={{marginLeft: "5px"}}>Delete</Button>
          </Form.Item>
        </Form>
      </Box>
    );
  }
}
const WrappedConfigurationsForm = Form.create()(ConfigForm);
export default WrappedConfigurationsForm;