import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Grommet } from 'grommet';
import SystemAlert from './views/SystemAlert';
import './App.css';
import Dashboard from "./views/Dashboard";
import OpsMan from "./views/OpsMan";
import NotFound from "./NotFound";
import { brandColor, region, userPoolId, userPoolWebClientId } from "./config";
import Amplify from 'aws-amplify';
import Portal from "./views/portal/Portal";

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //     sameSite: "strict" | "lax",
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },

    // // OPTIONAL - customized storage object
    // storage: MyStorage,

    // // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    //  // OPTIONAL - Hosted UI configuration
    // oauth: {
    //     domain: 'your_cognito_domain',
    //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //     redirectSignIn: 'http://localhost:3000/',
    //     redirectSignOut: 'http://localhost:3000/',
    //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // }
  }
});


const GlobalTheme = {
  global: {
    colors: {
      brand: brandColor
    },
    font: {
      family: 'Metric New'
    }
  }
};

const SystemError = false;

function App() {

  return (
    <Grommet theme={GlobalTheme}>
      <Router>
        {SystemError ? <SystemAlert /> : null}
        <Switch>
          <Route exact={true} path='/' component={Portal} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/ops' component={OpsMan} />
          <Route path='/*' component={NotFound} />
        </Switch>
      </Router>
    </Grommet>
  );
}

export default App;
