import React, { Component } from 'react';
import { Form, Input, DatePicker, Select } from 'antd';
import './index.css';
import { getNowFormatDate } from '../utils/CommonUtil';
import { DATE_PICKER_FORMAT, FARM_ALIAS } from '../config';

const { Option } = Select;

const CustomizedForm = Form.create({
  name: 'global_state',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      farm: Form.createFormField({
        ...props.farm,

      }),
      subject: Form.createFormField({
        ...props.subject,

      }),
      desc: Form.createFormField({
        ...props.desc,

      }),
      start_time: Form.createFormField({
        ...props.start_time,

      }),
      end_time: Form.createFormField({
        ...props.end_time,

      }),
    };
  },
  onValuesChange(_, values) {
    // console.log(values);
  },
})(
  class extends Component {
    // handleChange(date, dateString) {
    //   console.log("date is", date, dateString)
    // }

    render() {
      const { form, isEdit } = this.props
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 6 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 6 },
          sm: { span: 20 },
        },
      };
      return (
        <Form {...formItemLayout}>
          <div>
            <Form.Item hasFeedback label="Start time" htmlFor='start_time'>
              {getFieldDecorator('start_time',
                {
                  initialValue: getNowFormatDate(this.props.start_time),
                  rules: [{ type: 'object', required: true, message: 'Please select start time!' }]
                })(
                  <DatePicker showTime format={DATE_PICKER_FORMAT} style={{ width: '300px' }} />,
                )}
            </Form.Item>
            <Form.Item hasFeedback label="End time" htmlFor='end_time'>
              {getFieldDecorator('end_time',
                {
                  initialValue: getNowFormatDate(this.props.end_time),
                  rules: [{ type: 'object', required: true, message: 'Please select end time!' }]
                })(
                  <DatePicker showTime format={DATE_PICKER_FORMAT} style={{ width: '300px' }} />,
                )}
            </Form.Item>
          </div>
          {isEdit ?
            <Form.Item hasFeedback label="Farm" htmlFor='farm'>
              {getFieldDecorator('farm', {
                initialValue: this.props.farm || undefined,
                rules: [{ required: true, message: 'Farm is required!' }],
              })(
                <Select disabled>
                  {FARM_ALIAS.map(farm => <Option value={farm}>{farm.toUpperCase()}</Option>)}
                </Select>
              )
              }
            </Form.Item> : <Form.Item hasFeedback label="Farm" htmlFor='farm'>
              {getFieldDecorator('farm', {
                initialValue: ['us2'],
                rules: [{ type: 'array', required: true, message: 'Farm is required!' }],
              })(
                <Select mode="multiple" placeholder="Please select farm">
                  {FARM_ALIAS.map(farm => <Option value={farm}>{farm.toUpperCase()}</Option>)}
                </Select>
              )}
            </Form.Item>
          }
          {isEdit ?
            <Form.Item hasFeedback label="Subject" htmlFor='subject'>
              {getFieldDecorator('subject', {
                initialValue: this.props.subject || undefined,
                rules: [{ required: true, message: 'subject is required!' },
                { max: 50, message: 'subject must be lower than 50 characters' }],
              })(<Input placeholder='enter subject' />)}
            </Form.Item> : <Form.Item hasFeedback label="Subject" htmlFor='subject'>
              {getFieldDecorator('subject', {
                rules: [{ required: true, message: 'subject is required!' },
                { max: 50, message: 'subject must be lower than 50 characters' }],
              })(<Input placeholder='enter subject' />)}
            </Form.Item>
          }
          {isEdit ?
            <Form.Item hasFeedback label="Description" htmlFor='desc'>
              {getFieldDecorator('desc', {
                initialValue: this.props.desc || undefined,
                rules: [{ required: true, message: 'description is required!' }],
              })(<Input.TextArea placeholder='enter description' />)}
            </Form.Item>
            :
            <Form.Item hasFeedback label="Description" htmlFor='desc'>
              {getFieldDecorator('desc', {
                rules: [{ required: true, message: 'description is required!' }],
              })(<Input.TextArea placeholder='enter description' />)}
            </Form.Item>
          }
        </Form>
      );
    }
  });

export default CustomizedForm
